import { graphql } from "gatsby";
import React, { FunctionComponent } from "react";
import "./index.scss";

interface Props {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      };
    };
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
  }
`;

const IndexPage: FunctionComponent<Props> = ({ data }) => {
  const { name, tagline } = data.site.siteMetadata;

  return (
    <div className="Container">
      <h1>{name}</h1>
      <p>{tagline}</p>
    </div>
  );
};

export default IndexPage;
